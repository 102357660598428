import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography, CircularProgress, Button } from '@mui/material';
import { t } from './Util_format';
import config from './config/env.json';
import logopng from '@images/logo.png';
import { ErrorContainer, SuccessContainer } from '@style/styled.js';

function KillSessions() {
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const email = searchParams.get('email');
  const killString = searchParams.get('kill_string');

  const handleLogin = async () => {
    navigate("/login");
  }
  useEffect(() => {
    if (!email || !killString) {
      setError('Missing required parameters.');
      return;
    }
    handleKillSessions();
  }, [email, killString]);

  const handleKillSessions = async () => {
    setIsProcessing(true);
    setSuccessMessage(null);
    setError(null);

    try {
      const response = await fetch(config.API_DOMAIN_RETAIL + `/api/auth/session/kill-all`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          kill_string: killString,
          email: email
        })
      });

      if (!response.ok) {
        if (response.status === 500) {
          throw new Error("unknown_api_error");
        }
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setSuccessMessage(t('diconnection_completeed'));

    } catch (error) {
      setError(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Box id="page_kill_session" display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh" bgcolor="#f3f4f6">
      <img src={logopng} alt="Logo" style={{ width: '45px', height: '75px', marginBottom: '40px' }} />
      {isProcessing ? (
        <Box display="flex" flexDirection="column" alignItems="center">
          <CircularProgress size={48} />
          <Typography variant="h6" style={{ marginTop: '16px' }}>Disconnessione in atto...</Typography>
        </Box>
      ) : (
        <>
          {error && (
            <ErrorContainer isModal={false}>{t('error_during_disconnection')}</ErrorContainer>
          )}
          {successMessage && (
            <SuccessContainer isModal={false}>{successMessage}</SuccessContainer>
          )}
        </>
      )}
      <Button
        variant="contained"        
        sx={{
          borderRadius: '1rem',
          textTransform: 'none',
          backgroundColor: isProcessing ? '#AAAAAA !important' : undefined,
          color: isProcessing ? '#FFFFFF !important' : undefined,
          marginTop: '40px',
          width: '240px !important'
        }}
        onClick={handleLogin}
        disabled={isProcessing}
      >
        <Typography variant="body2" >
        {t('back_to_login')}
        </Typography>
      </Button>
    </Box>

  );
}

export default KillSessions;

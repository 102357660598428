import React, { useState, useEffect } from 'react';
import { IconButton, Grid2, InputAdornment, Card, CardContent, Typography, Select, MenuItem, InputLabel, FormControl, ListItemIcon, ListItemText, Box, ThemeProvider, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DescriptionIcon from '@mui/icons-material/Description';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { format } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';
import { enGB, it } from 'date-fns/locale';
import { fetchPageData, fetchCardTransactions, fetchBankAccountTransactions } from './Util_API_calls'; // Adjust the path as necessary
import { t } from './Util_format';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { startOfMonth, endOfMonth } from 'date-fns';
import 'dayjs/locale/en-gb';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { number_to_italian_currency } from './Util_format';
import ModalTransactionDetails from './ModalTransactionDetails';
import { useNavigate } from 'react-router-dom';
import { getCookieValue } from './App';
import { generatePDF } from './Util_generatePDF';
import { isCreditTransaction, isNOOPTransaction, isTopUpCardTransaction, transactionTypeKey } from './Util_app';
import pdf_icon from '@images/pdf_icon.svg';
import { PageTitle, theme, FDTTableFooter, FDTTableSortLabel, CurrencyTableCell } from '@style/styled.js';
import SearchIcon from '@mui/icons-material/Search';

function TransactionsTable({ transactions, onRowClick, dateRange, searchTerm, account, pageData }) {
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Check if an account is selected and transactions is an array and not empty
  if (!account) {
    return (
      <Typography variant="body1" style={{ marginTop: '40px' }}>
        {t('please_select_the_account')}
      </Typography>
    );
  } else if (!Array.isArray(transactions) || transactions.length === 0) {
    return (
      <Typography variant="body1" style={{ marginTop: '40px' }}>
        {t('no_transactions_to_show')}
      </Typography>
    );
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime()) ? format(date, 'dd/MM/yyyy hh:mm:ss', { locale: it }) : 'Invalid Date';
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedTransactions = [...transactions].sort((a, b) => {
    // Convert transaction date strings to Date objects
    // Use 'card_id' to determine if it is a credit card transaction
    const dateA = a.card_id ? new Date(a.transaction_created_at) : new Date(a.transaction_date_time);
    const dateB = b.card_id ? new Date(b.transaction_created_at) : new Date(b.transaction_date_time);

    if (sortConfig.key === 'date') {
      // Sort by date
      if (sortConfig.direction === 'asc') {
        return dateA - dateB; // Ascending order
      } else {
        return dateB - dateA; // Descending order
      }
    }

    // Sorting by other fields can be added here
    // Example: Sorting by amount
    if (sortConfig.key === 'amount') {
      if (parseFloat(a.amount) < parseFloat(b.amount)) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (parseFloat(a.amount) > parseFloat(b.amount)) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
    }

    // Add additional sorting logic for other fields if needed

    return 0; // Default return for equal items or unhandled sort keys
  });

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const renderSortLabel = (key, label) => (
    <FDTTableSortLabel
      active={sortConfig.key === key}
      direction={sortConfig.key === key ? sortConfig.direction : 'asc'}
      onClick={() => requestSort(key)}
    >
      {label}
    </FDTTableSortLabel>
  );

  const filteredTransactions = sortedTransactions.filter(transaction => {
    // Determine the correct date field based on the transaction type
    const transactionDateField = transaction.card_id === undefined ? 'transaction_date_time' : 'transaction_created_at';
    const transactionDate = new Date(transaction[transactionDateField]);

    if (transactionDate < dateRange.from || transactionDate > dateRange.to) {
      return false;
    }

    // Get the last four digits for card transactions
    const lastFourDigits = isTopUpCardTransaction(transaction)
      ? pageData.user_accounts.card_accounts.find(
        (e) => e.account_number === transaction.account_to
      )?.card_last_four_digits || ""
      : "";

    // Use the same description format as displayed in the table
    const transactionDescription = (
      transaction.card_id === undefined
        ? `${t(transactionTypeKey(transaction))}${lastFourDigits ? ` ${lastFourDigits}` : ""}${transaction.beneficiary_name ? ` - ${transaction.beneficiary_name}` : ""}`
        : transaction.merchant_name
          ? `${t(transaction.transaction_type)} ${transaction.merchant_name}`
          : t(transaction.transaction_type)
    ).toLowerCase();

    return transactionDescription.includes(searchTerm.toLowerCase());
  });

  const displayedTransactions = filteredTransactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <Table style={{ marginTop: '20px' }}>
        <TableHead>
          <TableRow>
            <TableCell>{renderSortLabel('date', t('date'))}</TableCell>
            <TableCell>{renderSortLabel('operation', t('operations'))}</TableCell>
            <TableCell>{renderSortLabel('description', t('description'))}</TableCell>
            <TableCell align="right">{renderSortLabel('amount', t('amount'))}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {displayedTransactions.map((transaction) => {
            // Determine the correct date field for display
            const transactionDateField = transaction.card_id === undefined ? 'transaction_date_time' : 'transaction_created_at';

            const beneficiaryInfo = transaction.beneficiary_name && transaction.beneficiary_surname
              ? ` - ${isCreditTransaction(transaction)
                ? t("received_from")
                : t("sent_to")} ${transaction.beneficiary_name} ${transaction.beneficiary_surname}`
              : "";

            return (
              <TableRow
                key={transaction.transaction_id}
                onClick={() => onRowClick(transaction)}
              >
                <TableCell style={{ cursor: "pointer" }}>
                  {formatDate(transaction[transactionDateField])}
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  {t(transactionTypeKey(transaction)) + beneficiaryInfo}
                  {transaction.status === "PENDING" && (
                    <AccessTimeIcon
                      style={{ marginLeft: "5px", color: "#CCCCCC" }}
                    />
                  )}
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  {transaction.description || transaction.merchant_name || ''}
                </TableCell>
                {transaction.status === "REJECTED" ||
                  isNOOPTransaction(transaction) ||
                  transaction.transaction_financial_status === "ON_HOLD" ? (
                  <TableCell align="right">
                    {transaction.status === "REJECTED"
                      ? t("cancelled")
                      : number_to_italian_currency(transaction.amount)}
                  </TableCell>
                ) : (
                  <CurrencyTableCell amount={number_to_italian_currency(transaction.amount)} isCredit={isCreditTransaction(transaction)} />
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <FDTTableFooter
        style={{
          marginTop: '20px'
        }}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredTransactions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t('rows_per_page')}
        labelDisplayedRows={
          ({ from, to, count }) => {
            return '' + from + '-' + to + ` ${t('of')} ` + count
          }
        }
      />
    </>
  );
}

function PageTransactions({ onDataChange, ...props }) {
  const [account, setAccount] = React.useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const navigate = useNavigate();
  const [pageData, setPageData] = useState(null);
  // Other state and variables
  const [dateRange, setDateRange] = useState({
    from: startOfMonth(new Date()),
    to: endOfMonth(new Date()),
  });

  const handleDateChange = (name) => (newValue) => {
    setDateRange({ ...dateRange, [name]: newValue });
  };

  const handleShowTransaction = (transaction) => {
    setSelectedTransaction(transaction);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTransaction(null);
  };

  const [userAccounts, setUserAccounts] = useState([]);

  // Inside the get_page_data function, after fetching the data
  const processAccountsData = (userData) => {
    const cardAccounts = userData.user_accounts.card_accounts.map(account => ({
      name: `${t('card_label')} **** **** **** ${account.card_last_four_digits}`, // Masked PAN
      card_id: `${account.card_id}`,
      type: 'card',
      info: account.card_last_four_digits
    }));

    const currentAccounts = userData.user_accounts.current_accounts.map(account => ({
      name: `${t('bank_accounts')} ${account.account_number}`,
      account_id: `${account.account_id}`,
      type: 'account',
      info: account.account_number
    }));


    const allAccounts = [...currentAccounts, ...cardAccounts];
    setUserAccounts(allAccounts);
  };

  useEffect(() => {
    get_page_data();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    setAccount(event.target.value);
  };

  const accountIcon = (type) => {
    return type === 'account' ?
      <AccountBalanceIcon sx={{ color: 'IconsInDropdown.main' }} /> :
      <CreditCardIcon sx={{ color: 'IconsInDropdown.main' }} />;
  };

  const getSelectedAccountType = () => {
    const selectedAccount = userAccounts.find(acc => acc.name === account);
    return selectedAccount ? selectedAccount.type : null;
  };

  const get_card_data = async () => {

    // Find the account using the name, then extract the card_id
    const selectedAccount = userAccounts.find(acc => acc.name === account);
    if (!selectedAccount) {
      console.error("No selected account found for card data fetch");
      return;
    }

    const transactionType = 'ALL'; // Adjust as needed
    setIsLoading(true);
    try {
      const data = await fetchCardTransactions(selectedAccount.card_id, transactionType, dateRange.from.toISOString().slice(0, 10).replace(/-/g, ''), dateRange.to.toISOString().slice(0, 10).replace(/-/g, ''), 'CET');

      if (data && data.transactions) {
        setTransactions(data.transactions);
      } else {
        setTransactions([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching card data:', error);
      setIsLoading(false);
    } finally {
      setIsLoading(false); // Ensure isLoading is set to false in both success and failure cases
    }
  };

  const get_bank_account_data = async () => {

    // Find the account using the name, then extract the account_id or any unique identifier
    const selectedAccount = userAccounts.find(acc => acc.name === account);
    if (!selectedAccount) {
      console.error("No selected account found for bank account data fetch");
      return;
    }

    // Use selectedAccount's account_id for fetching bank account data
    const transactionType = 'ALL'; // Adjust as needed
    setIsLoading(true);
    try {
      const data = await fetchBankAccountTransactions(selectedAccount.account_id, transactionType, dateRange.from.toISOString().slice(0, 10).replace(/-/g, ''), dateRange.to.toISOString().slice(0, 10).replace(/-/g, ''), 'CET');

      if (data && data.transactions) {
        // convert FDT specific transaction descriptions to user friendly transaction type
        data.transactions = data.transactions.map(transaction => {
          if (transaction.description && transaction.description.toLowerCase() === "epay purchase") {
            return {
              ...transaction,
              description: t('epay_purchase')
            };
          } else {
            return transaction;
          }
        });
        setTransactions(data.transactions);
      } else {
        setTransactions([]);
      }
    } catch (error) {
      console.error('Error fetching bank account data:', error);
      setTransactions([]);
    } finally {
      setIsLoading(false); // Ensure isLoading is set to false in both success and failure cases
    }
  };

  const get_page_data = async () => {
    setIsLoading(true);
    try {
      const apidata = await fetchPageData('transactions');

      let data = apidata.data;

      if (apidata.status === 'error') {
        if (apidata.fdt_error_code === 'TOKEN_EXPIRED') {
          navigate('/login?action=clean-cookie&reason=session_expired');
          return;
        }
        if (apidata.fdt_error_code === 'NO_BUSINESS_ACCOUNT_FOUND') {
          alert(t('error_no_business_account_found'));
        } else {
          alert(t('error_getting_data_from_server'));
        }
        return;
      }

      if (onDataChange) {
        onDataChange(data);
      }
      if (data) {
        setPageData(data);
        // Call processAccountsData here to process and set user accounts
        processAccountsData(data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (account) {
      const accountType = getSelectedAccountType();
      if (accountType === 'card') {
        get_card_data();
      } else if (accountType === 'account') {
        get_bank_account_data();
      }
    }
  }, [account, dateRange, userAccounts]);


  return (
    <ThemeProvider theme={theme}>
      {isModalOpen && (
        <ModalTransactionDetails
          transaction={selectedTransaction}
          onClose={handleCloseModal}
          pageData={pageData}
          info={userAccounts.find(acc => acc.name === account)?.info}
          isCardTransaction={getSelectedAccountType() === 'card'}
        />
      )}
      <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px', height: '100%' }}>
        <CardContent>
          <Grid2>
            <PageTitle>{t("bank_transactions")}<DescriptionIcon /></PageTitle>
          </Grid2>

          {/* Search row - components should stack horizontally */}
          <Grid2 container spacing={2} alignItems="center">
            {/* Account Select - Fixed smaller width when no selection */}
            <Grid2 xs={12} md={2.5}>

              <FormControl variant="outlined">
                <InputLabel id="account-select-label">
                  {t('select_account_bankaccount_or_card')}
                </InputLabel>
                <Select
                  id="account-select"
                  sx={{ width: '450px' }}
                  labelId="account-select-label"
                  value={account}
                  label={t('select_account')}
                  onChange={handleChange}
                  IconComponent={ListIcon}
                >
                  {userAccounts.map((item) => (
                    <MenuItem key={item.name} value={item.name}>
                      <ListItemIcon>
                        {accountIcon(item.type)}
                      </ListItemIcon>
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid2>

            {/* Date pickers */}
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getCookieValue('language') === 'en' ? enGB : it}>
              <Grid2 xs={12} md={2}>
                <DatePicker
                  label={t('date_from')}
                  value={dateRange.from}
                  onChange={handleDateChange('from')}
                  minDate={new Date(Date.now() - 359 * 24 * 60 * 60 * 1000)}
                  sx={{
                    width: '130px',
                    '& .MuiInputBase-input': {
                      paddingLeft: '1rem',
                      paddingRight: '1.75rem', // Increased to account for icon
                      fontSize: '0.875rem',
                      textAlign: 'center'     // Centers the text
                    },
                    '& .MuiInputAdornment-root': {
                      position: 'absolute',
                      right: '10px',           // Slightly adjusted icon position
                    }
                  }}
                />
              </Grid2>

              <Grid2 xs={12} md={2}>
                <DatePicker
                  label={t('date_to')}
                  value={dateRange.to}
                  onChange={handleDateChange('to')}
                  sx={{
                    width: '130px',
                    '& .MuiInputBase-input': {
                      paddingLeft: '1rem',
                      paddingRight: '1.75rem', // Increased to account for icon
                      fontSize: '0.875rem',
                      textAlign: 'center'     // Centers the text
                    },
                    '& .MuiInputAdornment-root': {
                      position: 'absolute',
                      right: '10px',           // Slightly adjusted icon position
                    }
                  }}
                />
              </Grid2>
            </LocalizationProvider>

            {/* Search field */}
            <Grid2 xs={12} md={4}>
              <TextField
                fullWidth
                name="search_filter_unique"
                label={t('search_filter')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid2>

            {/* PDF button - only shown when account selected */}
            {account && (
              <Grid2 xs="auto">
                <IconButton
onClick={() => generatePDF(
  getSelectedAccountType(),
  transactions,
  dateRange,
  null, // sortedTransactions not needed
  null, // selectedMonthYear not needed for transactions
  pageData,
  userAccounts.find(acc => acc.name === account)?.info
)}
                  disabled={isLoading}
                  sx={{ color: 'primary.main' }}
                >
                  <img src={pdf_icon} alt="PDF" style={{ height: '24px' }} />
                </IconButton>
              </Grid2>
            )}
          </Grid2>

          {/* Table section */}
          <Grid2 xs={12} sx={{ mt: 2 }}>
            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                <CircularProgress />
              </Box>
            ) : (
              <TransactionsTable
                key={searchTerm}
                transactions={transactions}
                dateRange={dateRange}
                searchTerm={searchTerm}
                account={account}
                onRowClick={handleShowTransaction}
                pageData={pageData}
              />
            )}
          </Grid2>
        </CardContent>
      </Card>
    </ThemeProvider >
  );
}

export default PageTransactions;
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { format, parseISO } from 'date-fns';
import { it } from 'date-fns/locale';
import { t } from './Util_format';
import { getDisplayName } from './Util_app';
import { number_to_italian_nosymbol, formatString } from './Util_format';
import logo_big from '@images/logo_big.png';

const generatePDF = (account_type, transactions, dateRange, sortedTransactions, selectedMonthYear, pageData, info) => {
  const isStatement = typeof selectedMonthYear === 'string';
  const isCardTransaction = account_type === "card";

  // Early validation checks
  if (!Array.isArray(transactions) || transactions.length === 0) {
    console.error('No transactions available');
    return;
  }

  if (!pageData || !pageData.details) {
    console.error('Missing page data');
    return;
  }

  // Process and sort transactions
  const processedTransactions = transactions
    .map(tx => {
      const dateKey = isCardTransaction ? "transaction_created_at" : "transaction_date_time";
      let txDate;
      try {
        // Handle different date formats
        txDate = tx[dateKey] instanceof Date ? tx[dateKey] : new Date(tx[dateKey]);
        if (isNaN(txDate.getTime())) {
          console.error('Invalid date:', tx[dateKey]);
          return null;
        }
      } catch (e) {
        console.error('Error parsing date:', tx[dateKey]);
        return null;
      }

      return {
        ...tx,
        date: format(txDate, "dd/MM/yyyy HH:mm", { locale: it }),
        parsedDate: txDate,
        description: isCardTransaction 
          ? (tx.merchant_name 
              ? `${t(tx.transaction_type)} ${tx.merchant_name}`
              : t(tx.transaction_type))
          : tx.description,
        amount: number_to_italian_nosymbol(tx.amount)
      };
    })
    .filter(tx => tx !== null) // Remove entries with invalid dates
    .sort((a, b) => b.parsedDate - a.parsedDate); // Sort by date descending

  // Filter transactions if needed
  let filteredTransactions = processedTransactions;
  if (!isStatement && dateRange) {
    filteredTransactions = processedTransactions.filter(tx => {
      return tx.parsedDate >= dateRange.from && tx.parsedDate <= dateRange.to;
    });
  } else if (isStatement && selectedMonthYear) {
    const [year, month] = selectedMonthYear.split('-').map(Number);
    filteredTransactions = processedTransactions.filter(tx => {
      const txDate = tx.parsedDate;
      return txDate.getFullYear() === year && txDate.getMonth() === month - 1;
    });
  }

  if (filteredTransactions.length === 0) {
    console.error('No transactions found after filtering');
    return;
  }

  // Create PDF
  const pdf = new jsPDF();
  
  // Add logo
  const logoSize = { width: 40, height: 40 };
  const pageWidth = pdf.internal.pageSize.getWidth();
  const centerX = (pageWidth - logoSize.width) / 2;
  pdf.addImage(logo_big, 'PNG', centerX, 10, logoSize.width, logoSize.height);

  // Add header information
  let yPos = 60;
  const lineHeight = 10;

  // Add title
  pdf.setFontSize(16);
  const title = isStatement 
    ? `${t('monthly_account_statement')} ${format(new Date(selectedMonthYear + '-01'), 'MMMM yyyy', { locale: it })}` 
    : `${t('transactions')} ${format(dateRange.from, 'dd/MM/yyyy', { locale: it })} - ${format(dateRange.to, 'dd/MM/yyyy', { locale: it })}`;
  pdf.text(title, 14, yPos);
  yPos += lineHeight * 2;

  // Add business information
  pdf.setFontSize(10);
  const businessName = getDisplayName(pageData.details);
  const address = [
    pageData.details.addr_line_1,
    pageData.details.addr_line_2,
    pageData.details.zip_code,
    pageData.details.city,
    pageData.details.state,
    pageData.details.country
  ].filter(Boolean).join(', ');

  pdf.text(`${t('account_owner_name')}: ${businessName}`, 14, yPos);
  yPos += lineHeight;
  pdf.text(`${t('account_owner_address')}: ${address}`, 14, yPos);
  yPos += lineHeight;
  
  // Add account/card info
  const accountInfo = isCardTransaction
    ? `${t('card_number')}: **** **** **** ${info || ''}`
    : `IBAN: ${info || ''}`;
  pdf.text(accountInfo, 14, yPos);
  yPos += lineHeight;

  // Add generation timestamp
  const timestamp = format(new Date(), 'dd/MM/yyyy HH:mm', { locale: it });
  pdf.text(`${t('generated_at')}: ${timestamp}`, 14, yPos);
  yPos += lineHeight * 2;

  // Calculate totals
  const totals = filteredTransactions.reduce((acc, tx) => {
    const amount = parseFloat(tx.amount.replace(/[.]/g, '').replace(',', '.'));
    if (isCardTransaction) {
      if (tx.direction?.toUpperCase() === "CREDIT") {
        acc.incoming += amount;
      } else {
        acc.outgoing += Math.abs(amount);
      }
    } else {
      if (tx.transaction_type?.toUpperCase() === "FUNDING" || tx.transaction_type?.toUpperCase() === "EXCHANGE") {
        acc.incoming += amount;
      } else if (tx.transaction_type?.toUpperCase() === "TRANSFER" || tx.transaction_type?.toUpperCase() === "INVOICE") {
        acc.outgoing += Math.abs(amount);
      }
    }
    return acc;
  }, { incoming: 0, outgoing: 0 });

  // Add summary table
  if (isStatement) {
    autoTable(pdf, {
      startY: yPos,
      head: [[
        { content: t('total_incoming'), styles: { halign: 'center' } },
        { content: t('total_outgoing'), styles: { halign: 'center' } },
        { content: t('currency'), styles: { halign: 'center' } }
      ]],
      body: [[
        { content: number_to_italian_nosymbol(totals.incoming), styles: { halign: 'right' } },
        { content: number_to_italian_nosymbol(totals.outgoing), styles: { halign: 'right' } },
        { content: 'EUR', styles: { halign: 'center' } }
      ]],
      styles: { fontSize: 9, cellPadding: 5 },
      headStyles: {
        fillColor: '#333333',
        textColor: '#FFFFFF',
        fontSize: 10,
        fontStyle: 'bold'
      },
      theme: 'grid'
    });
    yPos += 40;
  }

  // Add transactions table
  autoTable(pdf, {
    startY: isStatement ? yPos : yPos,
    head: [[
      { content: t('date'), styles: { halign: 'left' } },
      { content: t('description'), styles: { halign: 'left' } },
      { content: `${t('amount')} €`, styles: { halign: 'right' } }
    ]],
    body: filteredTransactions.map(tx => [
      tx.date,
      tx.description || '',
      { content: tx.amount, styles: { halign: 'right' } }
    ]),
    styles: {
      fontSize: 9,
      cellPadding: 5
    },
    headStyles: {
      fillColor: '#333333',
      textColor: '#FFFFFF',
      fontSize: 10,
      fontStyle: 'bold'
    },
    columnStyles: {
      0: { cellWidth: 40 },
      1: { cellWidth: 'auto' },
      2: { cellWidth: 30 }
    },
    theme: 'grid',
    showHead: 'everyPage',
    didDrawPage: function(data) {
      // Add header to each page
      if (data.pageNumber > 1) {
        pdf.setFontSize(8);
        pdf.text(title, 14, 10);
      }
      
      // Add page number
      pdf.setFontSize(8);
      const pageStr = `${t('page')} ${data.pageNumber}`;
      pdf.text(pageStr, pdf.internal.pageSize.getWidth() - 20, pdf.internal.pageSize.getHeight() - 10);
    }
  });

  // Save the PDF
  const fileName = isStatement ? 'statement.pdf' : 'transactions.pdf';
  pdf.save(fileName);
};

export { generatePDF };
import React, { useMemo, useState } from 'react';

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, TextField, MenuItem } from '@mui/material';
import { getCookieValue } from './App';
import { useNavigate } from 'react-router-dom';
import { t } from './Util_format';
import { get_api_url, isBusinessAccount } from './Util_API_calls';
import { ModalTitle, ErrorContainer } from '@style/styled.js';

function ModalCreatebeneficiary({ onClose, isTransferDirect, onTransferDirect }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentType, setPaymentType] = useState('SEPA'); // default payment type
  const navigate = useNavigate();

  const [beneficiaryDetails, setBeneficiaryDetails] = useState({
    name: '',
    surname: '',
    iban: '',
    email: '',
  });

  const isBusiness = useMemo(() => isBusinessAccount(), []);

  // Handle input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBeneficiaryDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Handle payment type change
  const handlePaymentTypeChange = (event) => {
    const { value } = event.target;
    setPaymentType(value);
  };

  const handleSave = async () => {
    setIsProcessing(true);
  
    try {
      const token = getCookieValue('firebaseToken');
      const { name, surname, iban, email } = beneficiaryDetails;
      const sanitizedIban = iban.replace(/\s+/g, '');
  
      // Validation
      const nameRegex = isBusiness ? /^[A-Za-z0-9]+(?: [A-Za-z0-9]+)*$/ : /^[A-Za-z]+(?: [A-Za-z]+)?$/;
      const surnameRegex = /^[A-Za-z]+(?: [A-Za-z]+)?$/;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
      class ValidationError extends Error {
        constructor(message) {
          super(message);
          this.name = 'ValidationError';
          this.errorType = 'validation';
        }
      }
      
      // Then use it like this:
      if (isBusiness && !nameRegex.test(name)) {
        throw new ValidationError(t('error_invalid_business_name'));
      }
      
      if (!isBusiness && (!nameRegex.test(name) || !surnameRegex.test(surname))) {
        throw new ValidationError(t('error_invalid_name_or_surname'));
      }
      
      if (!emailRegex.test(email)) {
        throw new ValidationError(t('error_beneficiary_invalid_email'));
      }
      
      if (sanitizedIban.trim().length < 5) {
        throw new ValidationError(t('iban_is_not_valid'));
      }
  
      const API_URL = await get_api_url();
  
      if (isTransferDirect) {
        const response = await fetch(`${API_URL}/api/baas/utilities/iban_check`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ iban: sanitizedIban }),
        });
  
        const data = await response.json();
        if (!response.ok) throw data;
  
        const bic = data.data.bank.bic.length === 8
          ? `${data.data.bank.bic}XXX`
          : data.data.bank.bic;
  
        onTransferDirect({
          payment_type: paymentType,
          beneficiary_iban: sanitizedIban,
          bank_bic: bic,
          beneficiary_name: name,
          beneficiary_surname: surname,
          beneficiary_currency: 'EUR',
          bank_country_code: data.data.country_code,
          beneficiary_email: email,
          beneficiary_address: data.data.bank.address
        });
      } else {
        const commonBody = {
          payment_type: paymentType,
          beneficiary_type: 'individual',
          beneficiary_name: name,
          beneficiary_surname: surname,
          beneficiary_iban: sanitizedIban,
          beneficiary_email: email,
        };
  
        const RETAIL_OR_BUSINESS = getCookieValue('RETAIL_OR_BUSINESS');
        if (RETAIL_OR_BUSINESS === 'BUSINESS') {
          commonBody.business_id = getCookieValue('business_id');
        }
  
        const response = await fetch(`${API_URL}/api/baas/beneficiaries/create`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${token}`,
          },
          body: new URLSearchParams(commonBody),
        });
  
        const data = await response.json();
        if (!response.ok) throw data;
  
        navigate(`/bank-transfers/make-payment/${data.beneficiary_id}`);
        window.location.reload();
      }
    } catch (error) {
      console.error('Error:', error);
  
      if (error.errorType === 'validation') {
        setErrorMessage(error.message);
        return;
      }
  
      switch (error.fdt_error_code) {
        case 'BAD_IBAN':
          setErrorMessage(t('iban_is_not_valid'));
          break;
        case 'DUPLICATED_BENEFICIARY':
          setErrorMessage(t('duplicated_iban'));
          break;
        case 'UNABLE_TO_REACH_IBANAPI':
          setErrorMessage(t('system_error_try_again_later'));
          break;
        default:
          setErrorMessage(t('system_error_try_again_later'));
      }
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <ModalTitle>
        {t(isTransferDirect ? 'new_bank_transfer' : 'create_new_beneficiary_title')}
      </ModalTitle>
      <DialogContent>
        {errorMessage && (
          <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
        )}
        <TextField
          select
          label={t('beneficiary_payment_type')}
          name="paymentType"
          fullWidth
          margin="dense"
          value={paymentType}
          onChange={handlePaymentTypeChange}
        >
          <MenuItem key="SEPA" value="SEPA">
            {t('new_bank_transfer_sepa')}
          </MenuItem>
          <MenuItem key="SWIFT" value="SWIFT">
            {t('new_bank_transfer_swift')}
          </MenuItem>
        </TextField>

        <TextField
          autoFocus
          margin="dense"
          id="beneficiary_name"
          name="name"
          label={isBusiness ? t('business_name') : t('beneficiary_name')}
          type="text"
          fullWidth
          value={beneficiaryDetails.name}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          id="beneficiary_surname"
          name="surname"
          label={isBusiness ? t('name_and_surname') : t('beneficiary_surname')}
          type="text"
          fullWidth
          value={beneficiaryDetails.surname}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          id="beneficiary_iban"
          name="iban"
          label={t('beneficiary_iban')}
          type="text"
          fullWidth
          value={beneficiaryDetails.iban}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          id="beneficiary_email"
          name="email"
          label={t('beneficiary_email')}
          type="email"
          fullWidth
          value={beneficiaryDetails.email}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} >
          {t('cancel')}
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          
          disabled={isProcessing}
        >
          {isProcessing ? <CircularProgress size={24} /> : t(isTransferDirect ? 'next_button' : 'save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalCreatebeneficiary;